















import Vue from "vue";
import LocationChooser from "@/components/mobile/LocationChooserMobile.vue";
import { truncateString } from "@/utils";
export default Vue.extend({
  name: "navbar-mobile",
  data: () => ({
    showModal: false
  }),
  computed: {
    location() {
      const pos = JSON.parse(localStorage.getItem("userPosition") as string);
      return pos;
    }
  },
  methods: {
    truncatestring: truncateString
  }
});
