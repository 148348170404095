
















































































































































import Vue from "vue";
import Header from "@/views/Header.vue";
import ItemCardDesktop from "@/components/desktop/ItemCardDesktop.vue";
import ItemCardMobile from "@/components/mobile/ItemCardMobile.vue";
import Footer from "@/components/desktop/Footer.vue";
import LocationChooser from "@/components/desktop/LocationChooser.vue";
import ItemCardHorizontal from "@/components/desktop/ItemCardHorizontal.vue";
import SkeletonItemCardDesktop from "@/components/skeleton/SkeletonItemCardDesktop.vue";

import LocationChooserModal from "@/components/mobile/LocationChooserMobile.vue";

import {
  getGeoLocationPosition,
  getCordinateAddress
} from "../utils/geo-location";

import { EventBus } from "@/utils/global-events";
import { UserPosition } from "../types";

export default Vue.extend({
  name: "home",
  components: {
    "header-top": Header,
    "item-card-desktop": ItemCardDesktop,
    "item-card-mobile": ItemCardMobile,
    "footer-area": Footer,
    "location-chooser": LocationChooser,
    ItemCardHorizontal,
    SkeletonItemCardDesktop,
    LocationChooserModal
  },
  data() {
    return {
      listViewMode: false,
      loading: true,
      keywords: "",
      page: 1,
      loadIconAnim: ""
    };
  },
  watch: {
    $route(to, from) {
      this.loading = true;
      this.getItems();
    }
  },
  methods: {
    getItems() {
      let url = "";

      if (this.$route.query.q) {
        const query = this.$route.query.q;
        url = `/items?lat=${this.userPosition.latitude}&lng=${this.userPosition.longitude}&page=${this.page}&q=${query}`;
      } else {
        url = `/items?lat=${this.userPosition.latitude}&lng=${this.userPosition.longitude}&page=${this.page}`;
      }
      this.$store.dispatch("getItems", url).then(() => {
        this.loading = false;
      });
    },
    toggleListViewMode() {
      this.listViewMode = !this.listViewMode;
    },
    getMoreItems() {
      this.page++;
      this.loadIconAnim = "spin";
      this.getItems();
      this.loadIconAnim = "";
    },
    getCurrLatLng(location: any) {
      this.$store.commit("setUserLocation", {
        label: location.address.label,
        latitude: location.position.lat,
        longitude: location.position.lng,
        title: location.title
      } as UserPosition);
      this.userPosition.latitude = location.position.lat;
      this.userPosition.longitude = location.position.lng;
      this.getItems();
    },

    searchItems() {
      this.loading = true;
      if (this.$route.fullPath != `/?q=${this.keywords}`) {
        this.$router
          .push({ path: "/", query: { q: this.keywords } })
          .catch(error => console.log(error));
      } else {
        this.loading = false;
      }
    }
  },
  computed: {
    userPosition: {
      get: function() {
        const pos = !!localStorage.getItem("userPosition");

        if (pos) {
          const location = JSON.parse(
            localStorage.getItem("userPosition") as string
          );
          return { latitude: location.latitude, longitude: location.longitude };
        } else {
          return { latitude: null, longitude: null };
        }
      },
      set: function(position: any) {
        //@ts-ignore
        this.latitude = position.latitude;
        //@ts-ignore
        this.longitude = position.longitude;
      }
    },

    items() {
      return this.$store.getters.getItems;
    }
  },

  mounted() {
    if (this.$vmq.lg) {
      this.getItems();
    } else {
      if (
        this.userPosition.latitude == null ||
        this.userPosition.longitude == null
      ) {
        getGeoLocationPosition()
          .then(position => {
            getCordinateAddress(
              position.coords.latitude,
              position.coords.longitude
            ).then(address => {
              this.getCurrLatLng(address);
            });
          })
          .catch((error: PositionError) => {
            console.log(error);
            this.$bvModal.show("bv-modal-example");
          });
      } else {
        this.getItems();
      }
    }
  },

  created() {
    EventBus.$on("change-global-locaton", (location: any) => {
      const pos = {
        latitude: location.position.lat,
        longitude: location.position.lng
      };
      this.userPosition = pos;

      alert(pos);
      this.getItems();
    });
  }
});
