import { User, AuthState, GoogleSignedUser } from "../types";
import http from "../utils/axios";

const state: AuthState = {
  isToken: !!localStorage.getItem("token"),
  isLoggedIn: false,
  token: localStorage.getItem("token") || null,
  loggedInUser: null,
};

const getters = {
  isLoggedIn: (state: AuthState) => state.isLoggedIn,
  loggedInUser: (state: AuthState) => state.loggedInUser,
  getToken: (state: AuthState) => state.token,
  isToken: (state: AuthState) => state.isToken,
};

const mutations = {
  setLoggedUser: (state: AuthState, user: User) => {
    state.loggedInUser = user;
  },
  setIsLogged: (state: AuthState) => {
    state.isLoggedIn = true;
  },
  setToken: (state: AuthState, token: string | null) => {
    state.token = token;
  },
};

const actions = {
  //@ts-ignore
  getLoggedUser: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      http
        .post("/auth", { token: state.token })
        .then((response) => {
          commit("setLoggedUser", response.data.user);
          commit("setIsLogged", true);
          console.log(state.user);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
  //@ts-ignore
  signIn: async (context, token: string): Promise<GoogleSignedUser> => {
    return http
      .post("/auth/google", { idToken: token })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
      });
  },
  //@ts-ignore
  logOut: (context) => {
    localStorage.removeItem("token");
    context.commit("setToken", null);
    context.commit("setLoggedUser", null);
    context.commit("setIsLogged", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
