




import Vue from "vue";
export default Vue.extend({
  props: {
    isFavorited: { type: Boolean, required: true }
  },
  computed: {
    iconName() {
      return this.isFavorited ? "heart-fill" : "heart";
    }
  }
});
