import Vue from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vuelidate from "vuelidate";

import GAuth from "vue-google-oauth2";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRupeeSign,
  faDirections,
  faCrosshairs,
  faPlus,
  faCar,
  faMobile,
  faBriefcase,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faTwitter,
  faInstagramSquare,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

import VueMediaQuery from "vue-media-q";
import { CoolSelectPlugin } from "vue-cool-select";
import "vue-cool-select/dist/themes/bootstrap.css";
import "leaflet/dist/leaflet.css";

import "./App.scss";

import http from "@/utils/axios";

Vue.use({
  install(Vue) {
    Vue.prototype.$api = http;
  },
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMediaQuery, {
  xs: 425,
  sm: 769,
  md: 992,
  lg: Infinity,
});

Vue.use(CoolSelectPlugin);
library.add(
  faRupeeSign,
  faFacebookSquare,
  faTwitterSquare,
  faTwitter,
  faInstagramSquare,
  faDirections,
  faGoogle,
  faCrosshairs,
  faPlus,
  faCar,
  faMobile,
  faBriefcase,
  faBuilding
);
Vue.component("fa-icon", FontAwesomeIcon);

const gauthOption = {
  clientId:
    "1061517953489-3d004hr4m5an1fnk3q11qce7s7t99ibc.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(Vuelidate);
Vue.use(GAuth, gauthOption);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
