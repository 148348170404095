



























































import Vue from "vue";
import { CoolSelect } from "vue-cool-select";
import http from "../../utils/axios";
import config from "../../config";
import {
  getGeoLocationPosition,
  getCordinateAddress
} from "../../utils/geo-location";

import { UserPosition } from "../../types";
import { EventBus } from "@/utils/global-events";

export default Vue.extend({
  components: {
    CoolSelect
  },
  props: {
    size: { type: String, default: null }
  },
  data: () => ({
    selected: null,
    items: [],
    loading: false,
    timeoutId: 0,
    noData: false,
    currLocation: null,
    searchText: ""
  }),
  methods: {
    /**
     * Get the location from search input
     */
    async onSearch(search: string) {
      const lettersLimit = 2;

      this.noData = false;
      this.loading = true;

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        const url = `${config.hereApi.autosuggestUrl}?apiKey=${config.hereApi.apiKey}&q=${search}&at=${config.hereApi.rootLat},${config.hereApi.rootLng}`;
        const response = await http.get(url);
        this.items = await response.data.items;

        this.loading = false;

        if (!this.items.length) this.noData = true;
      }, 500);
    },

    /**
     * Set current location
     */
    setCurrLocation() {
      const pos = this.getLocationFromLocalStorage();

      if (!pos) {
        getGeoLocationPosition()
          .then(position => {
            getCordinateAddress(
              position.coords.latitude,
              position.coords.longitude
            )
              .then(locationAddress => {
                //@ts-ignore
                this.selected = locationAddress;
                this.currLocation = this.selected;
                //@ts-ignore
                this.searchText = this.selected.address.label;
                this.emitChangeLocation();
              })
              .catch(error => console.error(error));
          })
          .catch((error: PositionError) => {
            this.$bvModal.show("bv-modal-example");
            console.error(error);
          });
      } else {
        this.selected = pos;
        this.searchText = pos.title;
      }
    },

    getLocationFromLocalStorage() {
      const isStoredLocation = !!localStorage.getItem("userPosition");

      if (isStoredLocation) {
        return JSON.parse(localStorage.getItem("userPosition") as string);
      } else {
        return null;
      }
    },

    /**
     * Emit location change event
     */
    emitChangeLocation() {
      this.$emit("changelocation", this.selected);
    }
  },
  mounted() {
    this.setCurrLocation();
  },
  computed: {
    style() {
      return this.size ? "height:" + this.size + "px" : "";
    }
  },
  created() {
    EventBus.$on("change-global-location", (location: any) => {
      this.selected = location;
      this.searchText = location.address.label;
    });
  }
});
