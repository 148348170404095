import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/components",
    name: "components",
    component: () => import("../views/Components.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/location",
    name: "location-chooser",
    component: () => import("../views/LocationChooserMobile.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("../views/SignIn.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/items/:id",
    name: "items",
    component: () => import("../views/Item.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/users/:id",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/postad",
    name: "postad",
    component: () => import("../views/PostAd.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

export const isAuthenticated = () => {
  return store.getters.isToken && store.getters.isLoggedIn;
};

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log(isAuthenticated());
    if (isAuthenticated()) {
      store
        .dispatch("getLoggedUser")
        .then(() => {
          next();
        })
        .catch((error) => {
          console.error(error);
          next({ path: "error" });
        });
    } else {
      next({ path: "/signin" });
    }
  } else {
    if (isAuthenticated()) {
      store
        .dispatch("getLoggedUser")
        .then(() => {
          next();
        })
        .catch((error) => {
          console.error(error);
          next({ path: "error" });
        });
    } else {
      next();
    }
  }
});

export default router;
