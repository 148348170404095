




































import Vue from "vue";
//import Header from "@/components/mobile/Header.vue";
import LocationList from "@/components/mobile/LocationList.vue";
import http from "../../utils/axios";
import config from "../../config";
export default Vue.extend({
  name: "location-chooser-mobile",
  components: {
    //"header-top": Header,
    "location-list": LocationList
  },

  data: () => ({
    items: [],
    loading: false,
    timeoutId: 0,
    noData: false,
    searchKeyword: ""
  }),
  methods: {
    async onSearch(location: string) {
      this.noData = false;
      this.loading = true;

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        const url = `${config.hereApi.autosuggestUrl}?apiKey=${config.hereApi.apiKey}&q=${location}&at=${config.hereApi.rootLat},${config.hereApi.rootLng}`;
        const response = await http.get(url);
        this.items = await response.data.items;

        this.loading = false;

        if (!this.items.length) this.noData = true;
      }, 500);
    }
  }
});
