




















import Vue from "vue";
export default Vue.extend({
  name: "skeleton-item-card-desktop"
});
