import http from "axios";
import config from "../config";

const locOptions: PositionOptions = {
  enableHighAccuracy: true,
  maximumAge: 0,
  timeout: 30 * 1000,
};

export const getGeoLocationPosition = () => {
  return new Promise<Position>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          reject(error);
        },
        locOptions
      );
    }
  });
};

export const getCordinateAddress = (lat: number, lng: number) => {
  return new Promise((resolve, reject) => {
    http
      .get(
        `${config.hereApi.revGeoCodeUrl}?apiKey=${config.hereApi.apiKey}&q&at=${lat},${lng}`
      )
      .then((response) => {
        resolve(response.data.items[0]);
      })
      .catch((error) => reject(error));
  });
};
