










import Vue from "vue";
import NavBar from "@/components/desktop/NavBar.vue";
import SidebarMobile from "@/components/mobile/SideBarMobile.vue";
import CategoryBar from "@/components/desktop/CategoryBar.vue";
import NavbarMobile from "@/components/mobile/NavbarMobile.vue";

export default Vue.extend({
  name: "header-top",
  components: {
    "nav-bar-desktop": NavBar,
    SidebarMobile,
    //"category-bar-desktop": CategoryBar,
    NavbarMobile
  }
});
