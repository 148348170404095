






import Vue from "vue";
import { UserPosition } from "../../types";
import { EventBus } from "@/utils/global-events";
export default Vue.extend({
  name: "location-list",
  props: {
    location: { type: Object, required: true }
  },
  methods: {
    setLocation() {
      const loc: UserPosition = {
        label: this.$props.location.address.label,
        latitude: this.$props.location.position.lat as number,
        longitude: this.$props.location.position.lng as number,
        title: this.$props.location.title
      };
      EventBus.$emit("change-global-location", this.$props.location);
      this.$store.dispatch("setUserLocation", loc).then(() => {
        this.$bvModal.hide("bv-modal-example");
        window.location.reload();
      });
    }
  }
});
