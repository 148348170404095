import { UserPosition } from "../types";
const state: UserPosition = {
  label: undefined,
  latitude: undefined,
  longitude: undefined,
  title: undefined,
};

const getters = {
  userPosition: (state: UserPosition) =>
    state || localStorage.getItem("userPosition"),
};

const mutations = {
  setUserLocation: (state: UserPosition, position: UserPosition) => {
    state.title = position.title;
    state.longitude = position.longitude;
    state.latitude = position.latitude;
    state.label = position.label;

    localStorage.setItem("userPosition", JSON.stringify(position));
  },
};

const actions = {
  //@ts-ignore
  setUserLocation(context, location: UserPosition) {
    return new Promise((resolve, reject) => {
      context.commit("setUserLocation", location);
      resolve();
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
