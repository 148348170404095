































































import Vue from "vue";
import { AxiosResponse } from "axios";
export default Vue.extend({
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.loggedInUser;
    },
    userLink() {
      //@ts-ignore
      return "/users/" + this.user._id;
    }
  },
  methods: {
    logOutHandler() {
      //@ts-ignore
      this.$gAuth
        .signOut()
        .then((response: AxiosResponse) => {
          this.$store.dispatch("logOut");
          window.location.replace("/");
        })
        .catch((error: unknown) => {
          console.log(error);
        });
    }
  }
});
