import Vue from "vue";
import Vuex from "vuex";
import http from "../utils/axios";
import AuthState from "./auth";
import userPositionState from "./user-location";
import { Item } from "../types";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    items: Array<any>(),
  },
  getters: {
    filterItems: (state) => (keyword: string) => {
      return state.items.filter((item) => {
        return item.title.match(new RegExp(keyword));
      });
    },
    getItems: (state) => state.items,
  },
  mutations: {
    setItems: (state, items) => {
      state.items = items;
    },
  },
  actions: {
    getItems(context, url) {
      return new Promise((resolve, reject) => {
        http
          .get(url)
          .then(function(response) {
            context.commit("setItems", response.data.items);
            resolve();
          })
          .catch((error) => reject(error));
      });
    },
    getMyItems(context, userId) {
      return new Promise<Item[]>((resolve, reject) => {
        http
          .get(`/items/?user=${userId}`)
          .then((response) => {
            resolve(response.data.items);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {
    auth: AuthState,
    userPosition: userPositionState,
  },
});
