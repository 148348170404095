




























import Vue from "vue";
import BtnFav from "@/components/BtnFav.vue";
import { truncateString, formatPrice } from "../../utils";
export default Vue.extend({
  name: "item-card-horizontal",
  components: {
    "btn-fav": BtnFav
  },

  props: {
    item: { type: Object, required: true },
    featured: { type: Boolean, default: false }
  },
  methods: {
    truncate: truncateString,
    format: formatPrice,
    favBtnClickHandler() {
      console.log("Woooh favorite");
    }
  }
});
