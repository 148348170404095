


































import Vue from "vue";
import BtnFav from "@/components/BtnFav.vue";
import { truncateString, formatPrice } from "../../utils";
export default Vue.extend({
  name: "item-card-desktop",
  components: {
    "btn-fav": BtnFav
  },
  data() {
    return {
      imgLoading: true
    };
  },

  props: {
    item: { type: Object, required: true },
    featured: { type: Boolean, default: false }
  },
  methods: {
    truncate: truncateString,
    format: formatPrice,
    favBtnClickHandler() {
      console.log("Woooh favorite");
    },
    setImgLoading() {
      this.imgLoading = false;
    },

    itemLink(): string {
      return `/items/${this.$props.item._id}`;
    }
  }
});
