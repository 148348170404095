export const truncateString = (str: string, len: number) => {
  let ending = "";
  let newLen = len;
  if (str.length >= len) {
    ending = "...";
    newLen = len - 3;
  }
  return str.substring(0, newLen) + ending;
};

export const formatPrice = (n: string) =>
  parseFloat(n)
    .toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    })
    .substring(1);

export const priceFormatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});
